/* ApplicationList.module.css */

.container {
    padding: 2rem 1rem;
    max-width: 1200px;
    margin: 0 auto;
    width: 100%;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
}

@media (max-width: 600px) {
    .header {
        flex-direction: column;
        align-items: flex-start;
        gap: 1rem;
    }
}

.title {
    font-size: 2rem;
    font-weight: 600;
    color: #2563eb;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
}

.addButton {
    padding: 0.5rem 1.5rem;
    background-color: #2563eb;
    color: white;
    border: none;
    border-radius: 8px;
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;
}

.addButton:hover {
    background-color: #1d4ed8;
}

/* Grid layout for application cards */
.appGrid {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.appCard {
    border-radius: 0.75rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
    border: 1px solid rgba(0, 0, 0, 0.03);
    background-color: white;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    width: 100%;
}

.appCard:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
}

.pendingCard {
    background-color: rgba(245, 158, 11, 0.08);
}

/* Horizontal card layout */
.cardContent {
    padding: 1.25rem;
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
    grid-template-areas:
        "info status actions";
    align-items: center;
    gap: 1rem;
}

@media (max-width: 768px) {
    .cardContent {
        grid-template-columns: 1fr;
        grid-template-areas:
            "info"
            "status"
            "actions";
        gap: 0.75rem;
    }
}

.appInfo {
    grid-area: info;
}

.appName {
    color: #2563eb;
    font-weight: 600;
    font-size: 1.25rem;
    margin: 0 0 0.25rem;
    cursor: pointer;
}

.appUrl {
    color: #64748b;
    font-size: 0.875rem;
    margin: 0;
}

.statusSection {
    grid-area: status;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

@media (max-width: 768px) {
    .statusSection {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
}

.chip {
    padding: 0.25rem 0.75rem;
    border-radius: 9999px;
    font-size: 0.75rem;
    font-weight: 500;
    color: white;
    display: inline-block;
    width: fit-content;
}

.chipSuccess {
    background-color: #10b981;
}

.chipWarning {
    background-color: #f59e0b;
}

.dateText {
    font-size: 0.75rem;
    color: #94a3b8;
}

.actions {
    grid-area: actions;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 0.5rem;
}

@media (max-width: 768px) {
    .actions {
        justify-content: space-between;
    }
}

.actionButtons {
    display: flex;
    gap: 0.5rem;
}

.actionButton {
    padding: 0.5rem 1rem;
    border-radius: 6px;
    font-weight: 600;
    font-size: 0.875rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: white;
    white-space: nowrap;
}

.primaryButton {
    background-color: #2563eb;
}

.primaryButton:hover {
    background-color: #1d4ed8;
}

.warningButton {
    background-color: #f59e0b;
}

.warningButton:hover {
    background-color: #d97706;
}

.iconButton {
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
    background: none;
    border: none;
    color: #64748b;
}

.iconButton:hover {
    background-color: rgba(0, 0, 0, 0.05);
}

.settingsIcon {
    color: #2563eb;
}

.deleteIcon {
    color: #ef4444;
}

/* Empty state styles - improved centered layout */
.emptyState {
    text-align: center;
    padding: 4rem 2rem;
    border-radius: 0.75rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
    border: 1px solid rgba(0, 0, 0, 0.03);
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.emptyStateIcon {
    font-size: 3rem;
    color: #94a3b8;
    margin-bottom: 1.5rem;
}

.emptyStateHeading {
    color: #2563eb;
    font-weight: 600;
    margin: 0 0 0.75rem;
    font-size: 1.5rem;
}

.emptyStateText {
    color: #64748b;
    margin-bottom: 2rem;
    max-width: 400px;
}

.centeredAddButton {
    padding: 0.75rem 2rem;
    background-color: #2563eb;
    color: white;
    border: none;
    border-radius: 8px;
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;
    margin: 0 auto;
}

.centeredAddButton:hover {
    background-color: #1d4ed8;
}

/* Error alert styles */
.errorAlert {
    padding: 1rem;
    background-color: #fee2e2;
    color: #b91c1c;
    border-radius: 0.5rem;
    margin-bottom: 1.5rem;
}

/* Modal styles */
.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modalContent {
    background-color: white;
    border-radius: 0.75rem;
    width: 90%;
    max-width: 500px;
    max-height: 90vh;
    overflow: auto;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.modalHeader {
    padding: 1.5rem;
    border-bottom: 1px solid #e5e7eb;
}

.modalTitle {
    margin: 0;
    font-size: 1.25rem;
    font-weight: 600;
    color: #0f172a;
}

.deleteModalHeader {
    padding: 1.5rem;
    border-bottom: 1px solid #e5e7eb;
    color: #ef4444;
}

.deleteModalTitle {
    margin: 0;
    font-size: 1.25rem;
    font-weight: 600;
    display: flex;
    align-items: center;
}

.modalBody {
    padding: 1.5rem;
}

.modalFooter {
    padding: 1.5rem;
    border-top: 1px solid #e5e7eb;
    display: flex;
    justify-content: flex-end;
    gap: 0.75rem;
}

/* Form styles */
.formGroup {
    margin-bottom: 1.5rem;
}

.formLabel {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: 500;
    color: #0f172a;
}

.formInput,
.formSelect {
    width: 100%;
    padding: 0.75rem;
    border-radius: 0.375rem;
    border: 1px solid #d1d5db;
    font-size: 1rem;
}

.inputError {
    border-color: #ef4444;
}

.errorText {
    color: #ef4444;
    font-size: 0.875rem;
    margin-top: 0.5rem;
}

.helperText {
    color: #64748b;
    font-size: 0.875rem;
    margin-top: 0.5rem;
}

.cancelButton {
    padding: 0.5rem 1rem;
    border-radius: 0.375rem;
    border: 1px solid #d1d5db;
    background-color: white;
    font-size: 0.875rem;
    font-weight: 500;
    cursor: pointer;
    color: #1f2937;
}

.submitButton {
    padding: 0.5rem 1rem;
    border-radius: 0.375rem;
    border: none;
    background-color: #2563eb;
    color: white;
    font-size: 0.875rem;
    font-weight: 500;
    cursor: pointer;
}

.submitButton:hover {
    background-color: #1d4ed8;
}

.submitButton:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.deleteButton {
    padding: 0.5rem 1rem;
    border-radius: 0.375rem;
    border: none;
    background-color: #ef4444;
    color: white;
    font-size: 0.875rem;
    font-weight: 500;
    cursor: pointer;
}

.deleteButton:hover {
    background-color: #dc2626;
}

.deleteWarningText {
    margin-bottom: 1rem;
    font-weight: 400;
    color: #374151;
}

.deleteDescriptionText {
    color: #6b7280;
}

/* Loader */
.loader {
    border: 3px solid #f3f3f3;
    border-radius: 50%;
    border-top: 3px solid #2563eb;
    width: 30px;
    height: 30px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

/* iOS-specific styles */
@supports (-webkit-touch-callout: none) {
    .container {
        width: 100%;
        padding-left: env(safe-area-inset-left);
        padding-right: env(safe-area-inset-right);
        -webkit-overflow-scrolling: touch;
    }

    .appCard {
        transform: translateZ(0);
        /* Force GPU acceleration */
    }
}