/* components/Support/AttachmentList.module.css */
.container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.attachment {
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 6px;
  background-color: #f9fafb;
  border: 1px solid #e5e7eb;
}

.fileIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 4px;
  background-color: #e0f2fe;
  color: #0284c7;
  margin-right: 12px;
}

.fileInfo {
  flex: 1;
  min-width: 0; /* Prevent text from causing overflow */
}

.fileName {
  font-size: 14px;
  font-weight: 500;
  color: #374151;
  margin-bottom: 2px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.fileDetails {
  font-size: 12px;
  color: #6b7280;
}

.actions {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-left: 12px;
}

.downloadButton, .deleteButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  border-radius: 4px;
  background: none;
  border: none;
  cursor: pointer;
  color: #6b7280;
  transition: all 0.2s ease;
}

.downloadButton:hover {
  background-color: #dbeafe;
  color: #2563eb;
}

.deleteButton:hover {
  background-color: #fee2e2;
  color: #dc2626;
}

.empty {
  color: #6b7280;
  font-size: 14px;
  font-style: italic;
}
