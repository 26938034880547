/* components/Layout/Header.module.css */
.header {
    background-color: white;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    padding: 0.8rem 0;
    position: sticky;
    top: 0;
    z-index: 1000;
    border-bottom: 1px solid var(--color-primary, #2563eb);
}

/* Special styling for the home page header */
.homeHeader {
    position: fixed;
    width: 100%;
    background-color: white;
    box-shadow: none;
    border-bottom: 1px solid var(--color-primary, #2563eb);
    /* Keep the blue border */
}

.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 1.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logo a {
    font-size: 1.5rem;
    font-weight: 700;
    color: #3B82F6;
    text-decoration: none;
}

.nav {
    display: flex;
    align-items: center;
    gap: 1.5rem;
}

.navLink {
    color: #5a6072;
    text-decoration: none;
    font-weight: 500;
    transition: color 0.15s ease-in-out;
    padding: 0.5rem 0.8rem;
    border-radius: 0.375rem;
}

.navLink:hover {
    color: #3B82F6;
    background-color: rgba(59, 130, 246, 0.05);
}

.navButton {
    background-color: #3B82F6;
    color: white;
    border: none;
    padding: 0.5rem 1.2rem;
    border-radius: 0.375rem;
    font-weight: 600;
    text-decoration: none;
    cursor: pointer;
    transition: background-color 0.15s ease-in-out;
}

.navButton:hover {
    background-color: #2563eb;
}

.mobileMenuButton {
    display: none;
    cursor: pointer;
}

.hamburgerIcon {
    width: 30px;
    height: 24px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.hamburgerIcon span {
    display: block;
    height: 3px;
    width: 100%;
    background-color: #3B82F6;
    border-radius: 3px;
    transition: all 0.3s ease;
}

.hamburgerIcon.open span:nth-child(1) {
    transform: translateY(10px) rotate(45deg);
}

.hamburgerIcon.open span:nth-child(2) {
    opacity: 0;
}

.hamburgerIcon.open span:nth-child(3) {
    transform: translateY(-10px) rotate(-45deg);
}

.logoContainer {
    display: flex;
    align-items: center;
    gap: 10px;
}

.logoImage {
    height: 32px;
    width: auto;
    display: block;
}

.logoText {
    font-size: 1.2rem;
    font-weight: 700;
    color: #3B82F6;
}

/* Add green accent to match RYZE.ai's color scheme */
.navLink:nth-child(2):hover {
    color: #10B981;
    /* Tailwind green-500 */
}

/* Add orange accent for third item */
.navLink:nth-child(3):hover {
    color: #F59E0B;
    /* Tailwind amber-500 */
}

/* components/Layout/Header.module.css */

/* Update the mobile menu styles */
@media (max-width: 768px) {
    .container {
        flex-wrap: wrap;
    }

    .logoText {
        font-size: 1rem;
    }

    .logoImage {
        height: 28px;
    }

    .mobileMenuButton {
        display: block;
        order: 2;
    }

    .logo {
        order: 1;
    }

    .nav {
        flex-direction: column;
        width: 100%;
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.5s ease-in-out;
        /* Increased transition time */
        order: 3;
        padding: 0;
        margin: 0;
    }

    /* Increase max-height for the open menu */
    .navOpen {
        max-height: 500px;
        /* Increased from 300px */
        margin-top: 1rem;
        padding-bottom: 1rem;
        /* Add padding at the bottom */
    }

    .nav .navLink,
    .nav .navButton {
        width: 100%;
        text-align: center;
        margin: 0.5rem 0;
        padding: 0.75rem;
    }

    .nav .navButton {
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
        /* Add margin at the bottom */
    }
}