/* components/Support/SupportTicketList.module.css */
.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: #f8f9fa;
  border-right: 1px solid #e9ecef;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid #e9ecef;
  background-color: white;
}

.header h2 {
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  color: #212529;
}

.newTicketButton {
  padding: 8px 16px;
  background-color: #3b82f6;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.newTicketButton:hover {
  background-color: #2563eb;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: #6c757d;
}

.emptyState {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 48px 16px;
  text-align: center;
  color: #6c757d;
}

.emptyIcon {
  margin-bottom: 16px;
  color: #adb5bd;
}

.createButton {
  margin-top: 16px;
  padding: 8px 16px;
  background-color: #3b82f6;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}

.ticketsList {
  overflow-y: auto;
  flex: 1;
}

.ticketItem {
  display: flex;
  flex-direction: column;
  padding: 16px;
  border-bottom: 1px solid #e9ecef;
  background-color: white;
  cursor: pointer;
  transition: background-color 0.1s ease;
}

.ticketItem:hover {
  background-color: #f8f9fa;
}

.ticketItem.selected {
  background-color: #e9f5fe;
  border-left: 3px solid #3b82f6;
}

.ticketItem.hasNewMessages {
  background-color: rgba(59, 130, 246, 0.05);
}

.ticketHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.statusIndicator {
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 12px;
  font-weight: 500;
}

.statusText {
  text-transform: capitalize;
}

.openIcon {
  color: #f59e0b;
}

.inProgressIcon {
  color: #3b82f6;
}

.resolvedIcon {
  color: #10b981;
}

.date {
  font-size: 12px;
  color: #6c757d;
}

.ticketTitle {
  font-size: 14px;
  font-weight: 500;
  color: #212529;
  margin-bottom: 8px;
  line-height: 1.4;
  word-break: break-word;
}

.ticketFooter {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 4px;
}

.newMessageBadge {
  display: flex;
  align-items: center;
  padding: 2px 8px;
  background-color: #3b82f6;
  color: white;
  border-radius: 12px;
  font-size: 12px;
  font-weight: 500;
}

.platformBadge {
  display: flex;
  align-items: center;
  padding: 2px 8px;
  background-color: #f3f4f6;
  color: #6c757d;
  border-radius: 12px;
  font-size: 12px;
  font-weight: 500;
}

@media (max-width: 768px) {
  .container {
    border-right: none;
    border-bottom: 1px solid #e9ecef;
  }
}