/* components/Support/SupportTicketDetail.module.css */
.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background-color: #f8f9fa;
  position: relative;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid #e9ecef;
  background-color: white;
  z-index: 10;
}

.headerLeft {
  display: flex;
  align-items: center;
}

.headerRight {
  display: flex;
  align-items: center;
  gap: 12px;
}

.backButton {
  display: flex;
  align-items: center;
  gap: 8px;
  background: none;
  border: none;
  color: #6b7280;
  cursor: pointer;
  padding: 6px 12px;
  font-size: 14px;
  border-radius: 4px;
  transition: background-color 0.2s ease;
}

.backButton:hover {
  background-color: #f3f4f6;
}

.infoButton {
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  color: #6b7280;
  cursor: pointer;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  transition: background-color 0.2s ease;
}

.infoButton:hover {
  background-color: #f3f4f6;
}

.content {
  display: flex;
  flex: 1;
  overflow: hidden;
  position: relative;
}

.mainSection {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  min-width: 0; /* Prevent flex items from overflowing */
}

.issueTitle {
  padding: 16px;
  border-bottom: 1px solid #e9ecef;
  background-color: white;
}

.issueTitle h1 {
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  color: #212529;
  word-break: break-word;
}

.messagesContainer {
  flex: 1;
  overflow-y: auto;
  padding: 16px;
  background-color: #f8f9fa;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.messageWrapper {
  display: flex;
  flex-direction: column;
  max-width: 80%;
}

.userMessage {
  align-self: flex-end;
}

.supportMessage {
  align-self: flex-start;
}

.systemMessage {
  align-self: center;
  max-width: 90%;
}

.supportIndicator {
  font-size: 12px;
  font-weight: 600;
  color: #4f46e5;
  margin-bottom: 4px;
}

.messageContent {
  padding: 12px 16px;
  border-radius: 12px;
  position: relative;
  word-wrap: break-word;
}

.userMessage .messageContent {
  background-color: #3b82f6;
  color: white;
  border-bottom-right-radius: 0;
}

.supportMessage .messageContent {
  background-color: #ffffff;
  color: #212529;
  border-bottom-left-radius: 0;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.systemMessage .messageContent {
  background-color: #f3f4f6;
  color: #6b7280;
  border-radius: 8px;
  font-style: italic;
  max-width: 100%;
  font-size: 14px;
  box-shadow: none;
}

.messageText {
  line-height: 1.5;
  font-size: 14px;
}

.messageText a {
  color: inherit;
  text-decoration: underline;
}

.userMessage .messageText a {
  color: #e0f2fe;
}

.messageTime {
  font-size: 11px;
  margin-top: 4px;
  opacity: 0.8;
  text-align: right;
}

.emptyMessages {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: #6c757d;
  font-style: italic;
}

.inputContainer {
  padding: 16px;
  background-color: white;
  border-top: 1px solid #e9ecef;
}

.inputForm {
  display: flex;
  gap: 8px;
}

.messageInput {
  flex: 1;
  padding: 12px 16px;
  border: 1px solid #e5e7eb;
  border-radius: 24px;
  font-size: 14px;
  outline: none;
  transition: border-color 0.2s ease;
}

.messageInput:focus {
  border-color: #3b82f6;
}

.sendButton, .attachButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.sendButton {
  background-color: #3b82f6;
  color: white;
}

.sendButton:hover {
  background-color: #2563eb;
}

.attachButton {
  background-color: #f3f4f6;
  color: #6b7280;
}

.attachButton:hover {
  background-color: #e5e7eb;
}

.sendButton:disabled, .attachButton:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

/* Sidebar styles */
.sidebar {
  width: 300px;
  background-color: white;
  border-left: 1px solid #e9ecef;
  overflow-y: auto;
  transform: translateX(100%);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  transition: transform 0.3s ease;
  z-index: 5;
}

.sidebar.visible {
  transform: translateX(0);
}

.sidebarHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid #e9ecef;
}

.sidebarHeader h3 {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
}

.closeSidebar {
  background: none;
  border: none;
  font-size: 24px;
  line-height: 1;
  color: #6b7280;
  cursor: pointer;
}

.sidebarContent {
  padding: 16px;
}

.sidebarSection {
  margin-bottom: 24px;
}

.sidebarSection h4 {
  margin: 0 0 8px 0;
  font-size: 14px;
  font-weight: 600;
  color: #6b7280;
}

.ticketStatus {
  display: inline-block;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
}

.ticketStatus.open {
  background-color: #fee2e2;
  color: #b91c1c;
}

.ticketStatus.in_progress {
  background-color: #e0f2fe;
  color: #0284c7;
}

.ticketStatus.resolved {
  background-color: #d1fae5;
  color: #059669;
}

.loadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.loading {
  color: #6c757d;
  font-size: 16px;
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  .backText {
    display: none;
  }
  
  .sidebar {
    width: 100%;
  }
  
  .messageWrapper {
    max-width: 90%;
  }
}