.footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    background: white;
    border-top: 1px solid #e5e7eb;
    padding: 0.75rem 0;
    z-index: 40;
}

.footerContent {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
}

.section {
    display: flex;
    align-items: center;
}

.privacyText {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: #6b7280;
    font-size: 0.875rem;
    margin: 0;
}

.smallHeartIcon {
    color: #ef4444;
}

.buttonText {
    display: inline;
}

.supportButton,
.feedbackButton {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 1rem;
    border-radius: 0.375rem;
    font-size: 0.875rem;
    transition: all 0.2s ease;
    background: transparent;
    border: 1px solid transparent;
    cursor: pointer;
}

.supportButton {
    border-color: #e5e7eb;
}

.supportButton:hover {
    background: #fef2f2;
    border-color: #fecaca;
}

.heartIcon {
    transition: all 0.2s ease;
}

.feedbackButton:hover {
    background: #f3f4f6;
    border-color: #d1d5db;
}

.feedbackIcon {
    color: #6b7280;
    transition: all 0.2s ease;
}

.feedbackButton:hover .feedbackIcon {
    color: #2563eb;
}

.rightSection {
    justify-content: flex-end;
}

/* Responsive styles */
@media (max-width: 640px) {
    .buttonText {
        display: none;
    }

    .supportButton,
    .feedbackButton {
        padding: 0.5rem;
    }

    .privacyText span {
        display: none;
    }

    .section {
        flex: 1;
        justify-content: center;
    }

    .rightSection {
        justify-content: flex-end;
    }
}

@media (max-width: 360px) {
    .footerContent {
        gap: 0.5rem;
    }
}