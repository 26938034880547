body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* iOS Safari specific CSS */
@supports (-webkit-touch-callout: none) {

  /* iOS devices */
  html,
  body {
    -webkit-overflow-scrolling: touch;
    height: 100%;
    width: 100%;
  }

  /* Fix for blank screen on Safari */
  #root {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
}