.container {
    padding: 8px 16px;
    border-top: 1px solid #e2e8f0;
    background-color: #f8fafc;
}

.quickReplies {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    justify-content: flex-start;
}

.quickReplyButton {
    padding: 8px 16px;
    background-color: #f1f5f9;
    border: 1px solid #cbd5e1;
    border-radius: 16px;
    font-size: 14px;
    color: #334155;
    cursor: pointer;
    transition: all 0.2s;
    white-space: nowrap;
}

.quickReplyButton:hover {
    background-color: #e2e8f0;
    border-color: #94a3b8;
}

@media (max-width: 768px) {
    .quickReplies {
        gap: 6px;
    }

    .quickReplyButton {
        padding: 6px 12px;
        font-size: 13px;
    }
}