/* WebsiteEdit.module.css */

.container {
    padding: 2rem 1rem;
    max-width: 1200px;
    margin: 0 auto;
    width: 100%;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
}

@media (max-width: 600px) {
    .header {
        flex-direction: column;
        align-items: flex-start;
        gap: 1rem;
    }
}

.title {
    font-size: 2rem;
    font-weight: 700;
    color: #0f172a;
    margin: 0;
}

.actions {
    display: flex;
    gap: 0.75rem;
    align-items: center;
}

.cancelButton {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 1rem;
    background-color: #f8fafc;
    color: #64748b;
    border: 1px solid #e2e8f0;
    border-radius: 0.375rem;
    font-weight: 500;
    font-size: 0.875rem;
    cursor: pointer;
    transition: all 0.2s;
}

.cancelButton:hover {
    background-color: #f1f5f9;
    color: #475569;
}

.saveButton {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 1rem;
    background-color: #2563eb;
    color: white;
    border: none;
    border-radius: 0.375rem;
    font-weight: 500;
    font-size: 0.875rem;
    cursor: pointer;
    transition: all 0.2s;
}

.saveButton:hover {
    background-color: #1d4ed8;
}

.saveButton:disabled {
    opacity: 0.6;
    cursor: not-allowed;
}

.successAlert {
    padding: 1rem;
    background-color: #dcfce7;
    color: #166534;
    border-radius: 0.375rem;
    margin-bottom: 1.5rem;
}

.errorAlert {
    padding: 1rem;
    background-color: #fee2e2;
    color: #b91c1c;
    border-radius: 0.375rem;
    margin-bottom: 1.5rem;
}

.formContainer {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1.5rem;
}

@media (min-width: 768px) {
    .formContainer {
        grid-template-columns: 1fr;
    }
}

.cardItem {
    border-radius: 0.75rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    background-color: white;
    overflow: hidden;
}

.cardHeader {
    padding: 1.25rem;
    font-size: 1.25rem;
    font-weight: 600;
    color: #0f172a;
    background-color: #f8fafc;
    border-bottom: 1px solid #e2e8f0;
}

.cardContent {
    padding: 1.5rem;
}

.formGroup {
    margin-bottom: 1.25rem;
}

.formLabel {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: 500;
    color: #334155;
}

.formInput,
.formSelect {
    display: block;
    width: 100%;
    padding: 0.75rem;
    font-size: 1rem;
    border: 1px solid #cbd5e1;
    border-radius: 0.375rem;
    background-color: white;
}

.formInput:focus,
.formSelect:focus {
    outline: none;
    border-color: #2563eb;
    box-shadow: 0 0 0 2px rgba(37, 99, 235, 0.1);
}

.formHelperText {
    margin-top: 0.5rem;
    font-size: 0.75rem;
    color: #64748b;
}

.infoText {
    color: #475569;
    font-size: 0.875rem;
}

.loaderContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3rem 0;
}

.loader {
    border: 3px solid #f3f3f3;
    border-radius: 50%;
    border-top: 3px solid #2563eb;
    width: 30px;
    height: 30px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.errorContainer {
    margin: 2rem 0;
    text-align: center;
}

.errorMessage {
    color: #b91c1c;
    font-size: 1.125rem;
    margin-bottom: 1rem;
}

.backButton {
    padding: 0.5rem 1rem;
    background-color: #2563eb;
    color: white;
    border: none;
    border-radius: 0.375rem;
    font-weight: 500;
    cursor: pointer;
}

.backButton:hover {
    background-color: #1d4ed8;
}

/* iOS-specific styles */
@supports (-webkit-touch-callout: none) {
    .container {
        width: 100%;
        padding-left: env(safe-area-inset-left);
        padding-right: env(safe-area-inset-right);
        -webkit-overflow-scrolling: touch;
    }
}