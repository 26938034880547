/* This CSS module is maintained for compatibility but is mostly unused
   since we're primarily using Material UI styling */

.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}

.backButton {
  display: flex;
  align-items: center;
  background: none;
  border: none;
  color: #1976d2;
  font-size: 14px;
  cursor: pointer;
  margin-right: 16px;
  padding: 8px 0;
}

.backButton span {
  margin-left: 8px;
}

/* These styles are kept as a fallback but the component now uses MUI styling */