/* HomePage.module.css - Matches RYZE.ai approach */
.fullPage {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f8fafc;
    text-align: center;
    overflow: hidden;
    z-index: 10;
    /* Lower than header/footer but higher than other content */
}

.centeredContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 500px;
    padding: 0 1.5rem;
    animation: fadeInUp 0.6s ease-out forwards;
    opacity: 0;
    transform: translateY(20px);
}

.logoWrapper {
    width: 120px;
    height: 120px;
    background-color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2rem;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
    border: 1px solid rgba(0, 0, 0, 0.05);
}

.logo {
    width: 80px;
    height: auto;
    max-width: 80%;
    object-fit: contain;
}

.title {
    font-size: 2.25rem;
    font-weight: 700;
    color: #0f172a;
    margin: 0 0 1rem 0;
    line-height: 1.2;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.highlight {
    color: #2563eb;
    /* Blue highlight */
    margin-bottom: 0.25rem;
}

.withAI {
    color: #0f172a;
}

.subtitle {
    font-size: 1.1rem;
    color: #475569;
    margin-bottom: 0.75rem;
    padding: 0 0.5rem;
    line-height: 1.5;
}

.description {
    font-size: 1rem;
    color: #64748b;
    margin-bottom: 2rem;
    padding: 0 0.75rem;
    line-height: 1.5;
}

.buttonGroup {
    display: flex;
    gap: 1rem;
    margin-bottom: 1.25rem;
    justify-content: center;
    width: 100%;
    max-width: 400px;
}

.login {
    background-color: #2563eb;
    color: white;
    border: none;
    padding: 0.75rem 1.5rem;
    border-radius: 0.375rem;
    font-weight: 600;
    font-size: 1rem;
    cursor: pointer;
    transition: all 0.2s ease;
    min-width: 120px;
    flex: 1;
}

.login:hover {
    background-color: #1e40af;
}

.register {
    background-color: white;
    color: #2563eb;
    border: 1px solid #2563eb;
    padding: 0.75rem 1.5rem;
    border-radius: 0.375rem;
    font-weight: 600;
    font-size: 1rem;
    cursor: pointer;
    transition: all 0.2s ease;
    min-width: 120px;
    flex: 1;
}

.register:hover {
    background-color: #f1f5f9;
}

.oauthWrapper {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
}

.privacyText {
    font-size: 0.875rem;
    color: #94a3b8;
    margin-top: 2.5rem;
}

.heart {
    color: #ef4444;
}

@keyframes fadeInUp {
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Responsive styles to match RYZE.ai */
@media (max-width: 640px) {
    .title {
        font-size: 1.75rem;
    }

    .logoWrapper {
        width: 100px;
        height: 100px;
        margin-bottom: 1.5rem;
    }

    .logo {
        width: 65px;
    }

    .subtitle {
        font-size: 1rem;
    }

    .description {
        font-size: 0.9rem;
        margin-bottom: 1.5rem;
    }

    .login,
    .register {
        padding: 0.7rem 1.25rem;
    }
}

/* Extra small screens */
@media (max-width: 375px) {
    .buttonGroup {
        flex-direction: column;
        gap: 0.8rem;
    }

    .logoWrapper {
        width: 80px;
        height: 80px;
    }

    .logo {
        width: 55px;
    }
}