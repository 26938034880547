.container {
    display: flex;
    flex-direction: column;
    height: 100%;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    background-color: #fff;
}

.header {
    display: flex;
    align-items: center;
    padding: 16px;
    background-color: #0070f3;
    color: white;
}

.robotIcon {
    margin-right: 12px;
    font-size: 24px;
}

.messages {
    flex: 1;
    overflow-y: auto;
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.message {
    max-width: 80%;
    padding: 12px 16px;
    border-radius: 18px;
    margin-bottom: 8px;
    word-break: break-word;
}

.userMessage {
    align-self: flex-end;
    background-color: #0070f3;
    color: white;
    border-bottom-right-radius: 4px;
}

.assistantMessage {
    align-self: flex-start;
    background-color: #f1f5f9;
    color: #334155;
    border-bottom-left-radius: 4px;
}

.errorMessage {
    background-color: #fee2e2;
    color: #b91c1c;
}

.messageContent {
    line-height: 1.5;
    white-space: pre-wrap;
}

.codeBlock {
    background-color: #1e293b;
    color: #f8fafc;
    padding: 12px;
    border-radius: 6px;
    font-family: monospace;
    white-space: pre-wrap;
    overflow-x: auto;
    margin: 8px 0;
}

.typingIndicator {
    display: flex;
    gap: 4px;
}

.typingIndicator span {
    width: 8px;
    height: 8px;
    background-color: #94a3b8;
    border-radius: 50%;
    animation: typing 1.4s infinite ease-in-out;
}

.typingIndicator span:nth-child(1) {
    animation-delay: 0s;
}

.typingIndicator span:nth-child(2) {
    animation-delay: 0.2s;
}

.typingIndicator span:nth-child(3) {
    animation-delay: 0.4s;
}

@keyframes typing {

    0%,
    60%,
    100% {
        transform: translateY(0);
    }

    30% {
        transform: translateY(-6px);
    }
}

.inputForm {
    display: flex;
    padding: 16px;
    border-top: 1px solid #e2e8f0;
}

.inputField {
    flex: 1;
    padding: 12px 16px;
    border: 1px solid #cbd5e1;
    border-radius: 24px;
    font-size: 14px;
    outline: none;
    transition: border-color 0.2s;
}

.inputField:focus {
    border-color: #0070f3;
}

.sendButton {
    margin-left: 12px;
    padding: 0 24px;
    background-color: #0070f3;
    color: white;
    border: none;
    border-radius: 24px;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s;
}

.sendButton:hover {
    background-color: #0051b3;
}

.sendButton:disabled {
    background-color: #94a3b8;
    cursor: not-allowed;
}

.humanSupportBanner {
    padding: 16px;
    background-color: #fef9c3;
    border-top: 1px solid #fde047;
    text-align: center;
}

.humanSupportButton {
    margin-top: 8px;
    padding: 8px 16px;
    background-color: #eab308;
    color: white;
    border: none;
    border-radius: 4px;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s;
}

.humanSupportButton:hover {
    background-color: #ca8a04;
}

/* Add or update these styles in AIInstallationHelper.module.css */

.chatButton {
    background-color: rgba(0, 112, 243, 0.1);
    /* Using your existing blue color */
    color: #0070f3;
    padding: 12px 20px;
    border-radius: 8px;
    border: 1px solid rgba(0, 112, 243, 0.3);
    transition: all 0.2s ease;
    font-size: 0.9rem;
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    font-weight: 500;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
}

.chatButton:hover {
    background-color: rgba(0, 112, 243, 0.15);
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.buttonIcon {
    width: 24px;
    height: 24px;
}

/* For the "Connect with Human" button - you could add a separate class */
.humanConnectButton {
    background-color: #ff6b00;
    /* Orange color to distinguish from the AI button */
    color: white;
    padding: 12px 20px;
    border-radius: 8px;
    border: none;
    transition: all 0.2s ease;
    font-size: 0.9rem;
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    font-weight: 500;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.humanConnectButton:hover {
    background-color: #e05f00;
    transform: translateY(-2px);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
}

/* For the "Start collecting data" button */
.actionButton {
    background-color: #0070f3;
    color: white;
    padding: 14px 24px;
    border-radius: 8px;
    border: none;
    transition: all 0.2s ease;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    box-shadow: 0 4px 12px rgba(0, 112, 243, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.actionButton:hover {
    background-color: #0051b3;
    transform: translateY(-2px);
    box-shadow: 0 6px 15px rgba(0, 112, 243, 0.3);
}

.actionButton:disabled {
    background-color: #94a3b8;
    cursor: not-allowed;
    transform: none;
    box-shadow: none;
}