/* components/Support/StatusSelector.module.css */
.container {
  position: relative;
  user-select: none;
}

.selector {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  background-color: white;
  border: 1px solid #e5e7eb;
  border-radius: 6px;
  cursor: pointer;
  font-size: 14px;
  transition: border-color 0.2s ease;
}

.selector:hover {
  border-color: #d1d5db;
}

.statusBadge {
  display: flex;
  align-items: center;
  gap: 6px;
  font-weight: 500;
}

.statusBadge.open {
  color: #f59e0b;
}

.statusBadge.in_progress {
  color: #3b82f6;
}

.statusBadge.resolved {
  color: #10b981;
}

.rotated {
  transform: rotate(180deg);
  transition: transform 0.2s ease;
}

.dropdown {
  position: absolute;
  top: 100%;
  right: 0;
  margin-top: 4px;
  width: 160px;
  background-color: white;
  border: 1px solid #e5e7eb;
  border-radius: 6px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  z-index: 10;
}

.option {
  padding: 10px 16px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.option:hover {
  background-color: #f9fafb;
}

.option:first-child {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.option:last-child {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.option.selected {
  background-color: #f3f4f6;
}
