/* components/Common/AuthErrorHandler.module.css */
.authErrorContainer {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 0.9);
    z-index: 1000;
    padding: 16px;
    display: flex;
    justify-content: center;
    animation: slideDown 0.3s ease-out;
}

.authErrorContent {
    background-color: #fff;
    border-left: 4px solid #f59e0b;
    padding: 16px 24px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    display: flex;
    align-items: center;
    max-width: 600px;
}

.authErrorIcon {
    font-size: 24px;
    margin-right: 16px;
}

.redirectingText {
    font-size: 14px;
    color: #64748b;
    margin-top: 4px;
    margin-left: 16px;
}

@keyframes slideDown {
    from {
        transform: translateY(-100%);
    }

    to {
        transform: translateY(0);
    }
}