.container {
    max-width: 1200px;
    margin: 2rem auto 2rem auto;
    padding: 0 1.5rem;
}

.websiteSelector {
    margin-bottom: 2rem;
    position: relative;
}

.selectorLabel {
    display: block;
    margin-bottom: 0.5rem;
    font-size: 0.875rem;
    font-weight: 500;
    color: #666;
}

.selectDropdown {
    width: 100%;
    max-width: 400px;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;
    border: 1px solid #ddd;
    appearance: none;
    background-color: white;
    cursor: pointer;
    font-size: 1rem;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 24 24' fill='none' stroke='%23333' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 1rem center;
    padding-right: 2.5rem;
}

.dashboardHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
}

.headerText h1 {
    font-weight: 600;
    color: #1a1a1a;
    margin-bottom: 0.5rem;
    font-size: 2rem;
}

.headerSubtitle {
    color: #666;
    font-size: 1rem;
}

.timeRangeSelector {
    display: flex;
    gap: 0.5rem;
}

.timeRangeButton {
    border-radius: 6px;
    padding: 0.75rem 1.5rem;
    font-weight: 500;
    border: 1px solid #ddd;
    background-color: white;
    cursor: pointer;
    transition: all 0.2s ease;
}

.timeRangeButton:hover {
    background-color: #f5f5f5;
}

.timeRangeButtonActive {
    composes: timeRangeButton;
    background-color: #2563eb;
    color: white;
    border-color: #2563eb;
}

.timeRangeButtonActive:hover {
    background-color: #1d4ed8;
}

.tabsContainer {
    margin-bottom: 2rem;
    border-bottom: 1px solid #eee;
}

.tabsList {
    display: flex;
    overflow-x: auto;
    list-style: none;
    padding: 0;
    margin: 0;
}

.tabItem {
    padding: 0.75rem 1.5rem;
    font-weight: 500;
    cursor: pointer;
    font-size: 0.95rem;
    border-bottom: 2px solid transparent;
    transition: all 0.2s ease;
}

.tabItem:hover {
    color: #2563eb;
}

.tabItemActive {
    composes: tabItem;
    color: #2563eb;
    font-weight: 600;
    border-bottom: 2px solid #2563eb;
}

/* Card Styles */
.card {
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.08);
    border-radius: 12px;
    background-color: white;
    height: 100%;
    margin-bottom: 2rem;
}

.cardContent {
    padding: 1.5rem;
}

.cardTitle {
    font-weight: 600;
    margin-bottom: 1rem;
    font-size: 1.25rem;
}

/* Grid Layout */
.gridContainer {
    display: flex;
    flex-wrap: wrap;
    margin: -1rem;
    margin-bottom: 1.5rem;
}

.gridItem {
    flex-grow: 1;
    padding: 1rem;
}

.gridItemQuarter {
    composes: gridItem;
    flex-basis: calc(25% - 2rem);
    min-width: 250px;
}

.gridItemHalf {
    composes: gridItem;
    flex-basis: calc(50% - 2rem);
    min-width: 300px;
}

.gridItemFull {
    composes: gridItem;
    flex-basis: calc(100% - 2rem);
}

/* Metric Cards */
.metricCard {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 1.5rem;
    transition: transform 0.2s ease;
}

.metricCard:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.metricLabel {
    font-size: 0.875rem;
    color: #666;
    margin-bottom: 0.5rem;
}

.metricValueContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 48px;
}

.metricValue {
    font-weight: 600;
    font-size: 2rem;
    color: #1a1a1a;
}

.trendChip {
    padding: 0.25rem 0.75rem;
    border-radius: 16px;
    font-weight: 500;
    font-size: 0.75rem;
    display: flex;
    align-items: center;
}

.trendChipPositive {
    composes: trendChip;
    background-color: rgba(76, 175, 80, 0.1);
    color: #2e7d32;
}

.trendChipNegative {
    composes: trendChip;
    background-color: rgba(244, 67, 54, 0.1);
    color: #d32f2f;
}

.trendIcon {
    margin-right: 0.25rem;
}

.miniChart {
    height: 60px;
    margin-top: 1rem;
    opacity: 0.9;
}

.miniChart:hover {
    opacity: 1;
}

.metricIcon {
    height: 60px;
    margin-top: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Charts */
.chartContainer {
    height: 360px;
    width: 100%;
    overflow: visible;
}

.deviceIconContainer {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
}

.deviceItem {
    display: flex;
    align-items: center;
    margin: 0 1rem;
}

.deviceIcon {
    margin-right: 0.5rem;
}

/* Tables */
.table {
    width: 100%;
    border-collapse: collapse;
}

.tableHeader th {
    text-align: left;
    padding: 0.75rem;
    border-bottom: 1px solid #eee;
    font-size: 0.875rem;
    color: #666;
    font-weight: 500;
}

.tableHeader th:last-child {
    text-align: right;
}

.tableRow td {
    padding: 1rem 0.75rem;
    border-bottom: 1px solid #f5f5f5;
    font-size: 0.875rem;
}

.tableRow td:last-child {
    text-align: right;
    font-weight: 500;
}

.tablePath {
    max-width: 220px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

/* Advanced Metrics */
.paperBox {
    padding: 1.5rem;
    border-radius: 8px;
    height: 100%;
}

.paperBlue {
    composes: paperBox;
    background-color: rgba(33, 150, 243, 0.05);
}

.paperGreen {
    composes: paperBox;
    background-color: rgba(76, 175, 80, 0.05);
}

.paperRed {
    composes: paperBox;
    background-color: rgba(244, 67, 54, 0.05);
}

.paperAmber {
    composes: paperBox;
    background-color: rgba(255, 152, 0, 0.05);
}

.paperPurple {
    composes: paperBox;
    background-color: rgba(156, 39, 176, 0.05);
}

.paperLabel {
    color: #666;
    font-size: 0.875rem;
    margin-bottom: 0.5rem;
}

.paperValue {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
}

.paperSubtext {
    color: #666;
    font-size: 0.875rem;
}

/* Insights */
.insightItem {
    padding: 1.5rem;
    border-radius: 8px;
    margin-bottom: 1.5rem;
    border-left: 4px solid;
    transition: transform 0.2s ease;
}

.insightItem:hover {
    transform: translateY(-2px);
}

.insightPositive {
    composes: insightItem;
    background-color: rgba(76, 175, 80, 0.12);
    border-color: #4caf50;
}

.insightNegative {
    composes: insightItem;
    background-color: rgba(244, 67, 54, 0.12);
    border-color: #f44336;
}

.insightSuggestion {
    composes: insightItem;
    background-color: rgba(33, 150, 243, 0.12);
    border-color: #2196f3;
}

.insightTitle {
    display: flex;
    align-items: center;
    font-weight: 600;
    margin-bottom: 0.75rem;
    font-size: 1rem;
}

.insightIcon {
    margin-right: 0.75rem;
}

.insightPositive .insightIcon {
    color: #4caf50;
}

.insightNegative .insightIcon {
    color: #f44336;
}

.insightSuggestion .insightIcon {
    color: #2196f3;
}

.insightText {
    color: #555;
    line-height: 1.6;
    margin-left: 1.5rem;
}

/* Empty state */
.emptyState {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 3rem 2rem;
    text-align: center;
}

.emptyStateIcon {
    font-size: 3rem;
    margin-bottom: 1.5rem;
    color: #ccc;
}

.emptyStateTitle {
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 0.75rem;
    color: #333;
}

.emptyStateText {
    max-width: 500px;
    color: #666;
    margin-bottom: 1.5rem;
}

.addWebsiteButton {
    padding: 0.75rem 1.5rem;
    background-color: #2563eb;
    color: white;
    border: none;
    border-radius: 0.375rem;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.addWebsiteButton:hover {
    background-color: #1d4ed8;
}

/* Loading */
.loadingContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
}

.spinnerContainer {
    position: relative;
    width: 40px;
    height: 40px;
}

.spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    border-top: 4px solid #2563eb;
    width: 100%;
    height: 100%;
    animation: spin 1s linear infinite;
}

.errorContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 40vh;
    text-align: center;
    padding: 2rem;
}

.errorMessage {
    color: #d32f2f;
    font-size: 1rem;
    margin-bottom: 1.5rem;
}

.actionButton {
    padding: 0.75rem 1.5rem;
    background-color: #2563eb;
    color: white;
    border: none;
    border-radius: 0.375rem;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.actionButton:hover {
    background-color: #1d4ed8;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .dashboardHeader {
        flex-direction: column;
        align-items: flex-start;
    }

    .timeRangeSelector {
        margin-top: 1rem;
    }

    .gridItemQuarter,
    .gridItemHalf {
        flex-basis: 100%;
    }
}