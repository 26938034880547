/* components/Support/CollaborationLink.module.css */
.container {
  display: flex;
  flex-direction: column;
  background-color: #f9fafb;
  border-radius: 6px;
  padding: 12px;
  border: 1px solid #e5e7eb;
}

.status {
  display: inline-block;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
  text-transform: capitalize;
  margin-bottom: 8px;
  align-self: flex-start;
}

.status.active {
  background-color: #dbeafe;
  color: #2563eb;
}

.status.pending {
  background-color: #fef3c7;
  color: #d97706;
}

.status.closed {
  background-color: #e5e7eb;
  color: #4b5563;
}

.ticketId {
  font-size: 14px;
  margin-bottom: 8px;
  color: #4b5563;
}

.link {
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 14px;
  color: #2563eb;
  text-decoration: none;
  margin-top: 4px;
  transition: color 0.2s ease;
}

.link:hover {
  color: #1d4ed8;
  text-decoration: underline;
}
