/* components/Support/SupportPage.module.css */
.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - 64px);
  /* Adjust based on your header height */
  background-color: #f8f9fa;
}

.fullWidthContainer {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  height: 100%;
  overflow-y: auto;
}

.listDetailContainer {
  display: flex;
  height: 100%;
  overflow: hidden;
}

.listInDetailView {
  width: 320px;
  border-right: 1px solid #e9ecef;
  overflow-y: auto;
}

.detailContainer {
  flex: 1;
  display: flex;
  height: 100%;
  overflow: hidden;
  background-color: white;
}

.formContainer {
  display: flex;
  justify-content: center;
  padding: 20px;
  height: 100%;
  overflow: auto;
}

/* Media queries for responsive layouts */
@media (max-width: 768px) {
  .listDetailContainer {
    flex-direction: column;
  }

  .listInDetailView {
    width: 100%;
    height: auto;
    max-height: 300px;
    border-right: none;
    border-bottom: 1px solid #e9ecef;
  }

  .detailContainer {
    height: 0;
    flex: 1;
  }
}